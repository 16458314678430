@import 'variables';
.row {
    margin: 0;
}

.react-bs-table-pagination button.btn {
    /*border: 1px;
    border-radius: 4px;
    border-color: transparent;
    /* background-color: $theme-color; 
    box-shadow: 0 2px 4px 0 rgba(115,115,115,0.5);*/
    font-size: 20px;
    color: #fff
}
div.react-bs-table.react-bs-table-bordered{
    background-color: white;
}

.react-bs-container-header > .table{
    margin-bottom: 0;
}
.react-bs-table-container .form-control{
    font-size: 18px;
}
.role-dialog-form {
    min-height: calc(100% - 96px) !important;
}

.sort-wrapper {
    
}

.sort-item {
    display: flex;
    align-items: center;
    cursor: n-resize;
    border: 1px solid $theme-color;
    border-bottom: none;
    background-color: #fff;
    padding: 5px;
    &:hover {
        background-color: $theme-color;
        color: #fff;
        svg {
            color: #fff !important;
        }
    }
    &:last-child {
        border-bottom: 1px solid $theme-color;
    }
    div {
        padding-left: 8px;
    }
}