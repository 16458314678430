// #dashboard-print-section {
//   visibility: hidden;
// }
// from bootstrap
// @media print{
//   *,:after,:before{
//     color:#000!important;
//     text-shadow:none!important;
//     background:0 0!important;
//     -webkit-box-shadow:none!important;
//     box-shadow:none!important
//   }
//   a,a:visited{
//     text-decoration:underline
//   }
//   a[href]:after{
//     content:" (" attr(href) ")"
//   }
//   abbr[title]:after{
//     content:" (" attr(title) ")"
//   }
//   a[href^="javascript:"]:after,a[href^="#"]:after{content:""}
//   blockquote,pre{border:1px solid #999;page-break-inside:avoid}
//   thead{display:table-header-group}
//   img,tr{page-break-inside:avoid}
//   img{max-width:100%!important}
//   h2,h3,p{orphans:3;widows:3}
//   h2,h3{page-break-after:avoid}
//   .navbar{display:none}
//   .btn>.caret,.dropup>.btn>.caret{border-top-color:#000!important}
//   .label{border:1px solid #000}
//   .table{border-collapse:collapse!important}
//   .table td,.table th{background-color:#fff!important}
//   .table-bordered td,.table-bordered th{border:1px solid #ddd!important}
// }
#dashboard-satistic-wrapper {
  width: 100%;
}
.show-print {
  visibility: hidden;
  display: none;
  img {
    width: 100%;
  }
}
@media print {
  // body * {
  //   visibility: hidden;
  // }
  #content-wrapper {
    width: 100vw;
  }

  .show-print {
    visibility: visible;
    display: initial;
  }

  #sidebar, #sidebar-wrapper, #footer, .navbar, #print-button-wrapper, .hide-print {
    visibility: hidden;
    display: none;
  }

  #dashboard-print-section,
  #dashboard-print-section * {
    visibility: visible;
  }

  #dashboard-print-section {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    font-weight: bold;
  }

  #content-root, #content-wrapper {
    height: unset !important;
    overflow: visible !important;
  }

  .pagebreak { page-break-before: always; }

  #dashboard-satistic-wrapper>div {
    width: 100%;
  }
}