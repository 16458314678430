@import 'variables';
.Title-Panel {
    width: 100%;
    height: 4.5vh;
    min-height: 50px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    padding: 0px 7.5px;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.Knowledge-Page {
    min-height: 100%;
    padding: 20px 20px;
    background-color: rgba(238, 238, 238, 1);
}

.Sub-Items {
    font-size: 18px;
    cursor: pointer;
}

.Title-label {
    font-size: 20px;
    padding-left: 10px;
    color: #4A4A4A;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Level-Panel {
    width: 100%;
    padding: 15px 1.5vw 0px 1.5vw;
    display: flex;
    flex-wrap: wrap;
    &>.Sub-Level:last-child {
        cursor: default;
    }
}

.Sub-Level {
    color: $theme-color;
    font-size: 21.5px;
    cursor: pointer;
    height: 28px;
}

.Document-Items {
    height: 125px;
    width: 110px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    margin: 0px 5px 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.flex-media {
    width: 100%;
    min-height: 20.5vh;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    padding: 0px 7.5px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
}

.Sub-PanelBottom {
    width: 99%;
    display: block;
    text-align: left;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    margin-top: 1px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    margin-bottom: 5px;
}

.Sub-Panel {
    width: 99%;
    display: block;
    text-align: left;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    margin-top: 1px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
}

.Media-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    width: 97%;
    margin: -16px 1.5vw 15px 1.5vw;
    &.desktop {
        .flex-media:nth-child(3n + 1) {
            margin-right: 8px;
        }
        .flex-media:nth-child(3n + 2) {
            margin-right: 8px;
            margin-left: 8px;
        }
        .flex-media:nth-child(3n) {
            margin-left: 8px;
        }
    }
}