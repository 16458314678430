
.App{
  text-align: center;
}

.container {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    width: 100vw;
}


body {
    /*min-height: 100vh;*/
    height: 100%;
    width: 100%;
}


.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, 
.col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, 
.col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, 
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, 
.col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, 
.col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 0px;
    padding-left: 0px;
}

.container {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

/* .modal-container {
  position: relative;
}
.modal-container .modal, .modal-container .modal-backdrop {
  position: absolute;
}
.modal-header {
    font-size: 25px;
    text-align: start;
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e5e5e5;
}
.modal-body {
    position: relative;
    padding: 15px;
    font-size: 18px;
    text-align: start;
}
.modal-footer {
    padding: 10px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
} */
.btn{
    padding: 0px 10px;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 10px;
}

a {
    color: rgb(52, 73, 94);
    text-decoration: none;
}