.row {
  margin: 0; }

.react-bs-table-pagination button.btn {
  /*border: 1px;
    border-radius: 4px;
    border-color: transparent;
    /* background-color: $theme-color; 
    box-shadow: 0 2px 4px 0 rgba(115,115,115,0.5);*/
  font-size: 20px;
  color: #fff; }

div.react-bs-table.react-bs-table-bordered {
  background-color: white; }

.react-bs-container-header > .table {
  margin-bottom: 0; }

.react-bs-table-container .form-control {
  font-size: 18px; }

.role-dialog-form {
  min-height: calc(100% - 96px) !important; }

.sort-item {
  display: flex;
  align-items: center;
  cursor: n-resize;
  border: 1px solid #34495E;
  border-bottom: none;
  background-color: #fff;
  padding: 5px; }
  .sort-item:hover {
    background-color: #34495E;
    color: #fff; }
    .sort-item:hover svg {
      color: #fff !important; }
  .sort-item:last-child {
    border-bottom: 1px solid #34495E; }
  .sort-item div {
    padding-left: 8px; }

.menu-arrow {
  transition: all 0.25s; }

.menu-arrow.open {
  transform: rotate(180deg); }

.Feed-Page {
  height: 100%;
  padding: 10px 20px 0px 20px;
  background-color: #eeeeee; }

.feed-container {
  display: flex;
  justify-content: center; }

.status-container {
  margin-bottom: 3px;
  padding-left: 17px; }

.complaintInfo-container {
  display: flex;
  justify-content: center; }

.gallery-container {
  display: flex;
  justify-content: center;
  flex: 1; }

.status-box {
  min-width: 90px;
  display: inline-block;
  background-color: #3498DB;
  height: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #ffffff;
  align-items: center;
  justify-content: right;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px; }

.flex-complaint {
  width: 100%;
  z-index: 1;
  /*height: 20.5vh;
      min-height: 50px;*/
  min-height: 20.5vh;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  padding: 0px 7.5px;
  text-align: center;
  align-items: center;
  justify-content: center; }

.flex-reply {
  width: 100%;
  min-height: 12.5vh;
  border-radius: 4px;
  z-index: -1;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: rgba(230, 239, 249, 0.5);
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  padding: 0px 7.5px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 2.6px;
  align-items: center;
  justify-content: center; }

.filter-Panel-L {
  width: 100%;
  min-width: 58px;
  flex: 0.3;
  height: 50%;
  min-height: 50px;
  border-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  padding: 15px 3.5px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  margin-left: 1vw;
  cursor: pointer; }

.filter-Panel-C {
  width: 100%;
  min-width: 77px;
  flex: 0.5;
  height: 50%;
  min-height: 50px;
  border-radius: 0px;
  border-right: 1px;
  border-left: 1px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  padding: 15px 3.5px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.filter-Panel-R {
  width: 100%;
  min-width: 58px;
  flex: 0.5;
  height: 50%;
  min-height: 50px;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  padding: 15px 3.5px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.header-Panel {
  width: 100%;
  min-width: 120px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  /* padding: 15px 3.5px; */
  text-align: center;
  /* margin-bottom: 0px; */
  margin-top: 0px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center; }

.filter-label {
  font-size: 20px;
  color: #34495E;
  align-items: center;
  justify-content: center; }

.info-label {
  color: #34495E;
  margin-bottom: 0px;
  text-align: start;
  font-size: 20px;
  font-weight: bold;
  line-height: 27px; }

.flex-complaint-info {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center; }

.flex-item-info {
  background-color: cornflowerblue;
  width: 100px;
  height: 100px;
  margin: 10px;
  margin-left: 0px;
  margin-top: 4px; }

.complaint-description {
  color: #4A4A4A;
  font-size: 22px;
  line-height: 30px;
  word-break: break-word; }

.reply-description {
  height: 100%;
  color: #4A4A4A;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 10px; }

.Title-Panel {
  width: 100%;
  height: 4.5vh;
  min-height: 50px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  padding: 0px 7.5px;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 20px;
  align-items: center;
  justify-content: center; }

.Knowledge-Page {
  min-height: 100%;
  padding: 20px 20px;
  background-color: #eeeeee; }

.Sub-Items {
  font-size: 18px;
  cursor: pointer; }

.Title-label {
  font-size: 20px;
  padding-left: 10px;
  color: #4A4A4A;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.Level-Panel {
  width: 100%;
  padding: 15px 1.5vw 0px 1.5vw;
  display: flex;
  flex-wrap: wrap; }
  .Level-Panel > .Sub-Level:last-child {
    cursor: default; }

.Sub-Level {
  color: #34495E;
  font-size: 21.5px;
  cursor: pointer;
  height: 28px; }

.Document-Items {
  height: 125px;
  width: 110px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  margin: 0px 5px 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.flex-media {
  width: 100%;
  min-height: 20.5vh;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  padding: 0px 7.5px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 16px;
  align-items: center;
  justify-content: center; }

.Sub-PanelBottom {
  width: 99%;
  display: block;
  text-align: left;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  margin-top: 1px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
  margin-bottom: 5px; }

.Sub-Panel {
  width: 99%;
  display: block;
  text-align: left;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  margin-top: 1px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5); }

.Media-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 97%;
  margin: -16px 1.5vw 15px 1.5vw; }
  .Media-container.desktop .flex-media:nth-child(3n + 1) {
    margin-right: 8px; }
  .Media-container.desktop .flex-media:nth-child(3n + 2) {
    margin-right: 8px;
    margin-left: 8px; }
  .Media-container.desktop .flex-media:nth-child(3n) {
    margin-left: 8px; }

#dashboard-satistic-wrapper {
  width: 100%; }

.show-print {
  visibility: hidden;
  display: none; }
  .show-print img {
    width: 100%; }

@media print {
  #content-wrapper {
    width: 100vw; }
  .show-print {
    visibility: visible;
    display: initial; }
  #sidebar, #sidebar-wrapper, #footer, .navbar, #print-button-wrapper, .hide-print {
    visibility: hidden;
    display: none; }
  #dashboard-print-section,
  #dashboard-print-section * {
    visibility: visible; }
  #dashboard-print-section {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    font-weight: bold; }
  #content-root, #content-wrapper {
    height: unset !important;
    overflow: visible !important; }
  .pagebreak {
    page-break-before: always; }
  #dashboard-satistic-wrapper > div {
    width: 100%; } }

body {
  font-size: 20px; }

.row input[type=text], .row input[type=number], .row input[type=email], .row input[type=password], .row select {
  width: 100%;
  border: 1px solid #C8C8C8;
  border-radius: 4px;
  background-color: #fff;
  padding-left: 13px; }

input[type=text]:disabled, input[type=number]:disabled, input[type=checkbox]:disabled, textarea[type=text]:disabled {
  background-color: #dddddd !important; }

.Knowledge-Page input[type=text] {
  border: unset;
  border-radius: 0px;
  background-color: unset; }

.row input[type=text].empty {
  border: 1px solid transparent;
  color: transparent; }

.row button:not(.MuiButtonBase-root) {
  border: 1px;
  border-radius: 4px;
  background-color: #34495E; }
  .row button:not(.MuiButtonBase-root).create-account {
    color: #fff; }

.mui-fab button {
  border-radius: 50%; }

.input-group-addon {
  background-color: #34495E;
  color: white;
  border: 0px; }

.pagination a {
  font-size: 18px; }

label {
  margin-bottom: 0; }

.input-group .input-group-addon + .rdt input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.complaint-select select, .rdt input, select.form-control {
  font-size: 18px; }

.pb-10 {
  padding-bottom: 10px; }
