@import 'variables';
.Feed-Page {
    height: 100%;
    padding: 10px 20px 0px 20px;
    background-color: rgba(238, 238, 238, 1);
}

.feed-container {
    display: flex;
    justify-content: center;
}

.status-container {
    margin-bottom: 3px;
    padding-left: 17px;
}

.complaintInfo-container {
    display: flex;
    justify-content: center;
}

.gallery-container {
    display: flex;
    justify-content: center;
    flex: 1;
}

.status-box {
    min-width: 90px;
    display: inline-block;
    background-color: #3498DB;
    height: 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #ffffff;
    align-items: center;
    justify-content: right;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
}

.flex-complaint {
    width: 100%;
    z-index: 1;
    /*height: 20.5vh;
      min-height: 50px;*/
    min-height: 20.5vh;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    padding: 0px 7.5px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.flex-reply {
    width: 100%;
    min-height: 12.5vh;
    border-radius: 4px;
    z-index: -1;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: rgba(230, 239, 249, 0.5);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    padding: 0px 7.5px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 2.6px;
    align-items: center;
    justify-content: center;
}

.filter-Panel-L {
    width: 100%;
    min-width: 58px;
    flex: 0.3;
    height: 50%;
    min-height: 50px;
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    padding: 15px 3.5px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
    cursor: pointer;
}

.filter-Panel-C {
    width: 100%;
    min-width: 77px;
    flex: 0.5;
    height: 50%;
    min-height: 50px;
    border-radius: 0px;
    border-right: 1px;
    border-left: 1px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    padding: 15px 3.5px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.filter-Panel-R {
    width: 100%;
    min-width: 58px;
    flex: 0.5;
    height: 50%;
    min-height: 50px;
    border-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    padding: 15px 3.5px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header-Panel {
    width: 100%;
    min-width: 120px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(120, 120, 120, 0.5);
    /* padding: 15px 3.5px; */
    text-align: center;
    /* margin-bottom: 0px; */
    margin-top: 0px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
}

.filter-label {
    font-size: 20px;
    color: $theme-color;
    align-items: center;
    justify-content: center;
}

.info-label {
    color: $theme-color;
    margin-bottom: 0px;
    text-align: start;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
}

.flex-complaint-info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

.flex-item-info {
    background-color: cornflowerblue;
    width: 100px;
    height: 100px;
    margin: 10px;
    margin-left: 0px;
    margin-top: 4px;
}

.complaint-description {
    color: #4A4A4A;
    font-size: 22px;
    line-height: 30px;
    word-break: break-word;
}

.reply-description {
    height: 100%;
    color: #4A4A4A;
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 10px;
}